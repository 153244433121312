html, body {
	margin: 0;
	padding: 0;
	height:100%;
	width: 100%;
}

*, :after, :before {
    box-sizing: border-box;
}
